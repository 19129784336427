import axios from 'axios';

import { setAxiosConfig } from '../util/SetDefaultLists';

export const apiRequest = async (config, endpoint, method = 'get', data = null) => {
  try {
    const axiosConfig = setAxiosConfig(config, endpoint, method, data);
    const response = await axios(axiosConfig);
    return response.data;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${endpoint}:`, error.message);
    return { error: error.message || 'Unexpected error occurred.' };
  }
};

export const apiRequestDev = async (config, endpoint, method = 'get', data = null, upload = false) => {
  try {
    const axiosConfig = setAxiosConfig(config, endpoint, method, data, upload, true);
    const response = await axios(axiosConfig);
    return response;
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} request to ${endpoint}:`, error.message);
    return { message: error.response.data.message || error.message || 'Unexpected error occurred.' };
  }
};
