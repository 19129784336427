import React, { useState } from 'react';

import openEye from '../../../../assets/images/icons/eye-open.svg';
import closedEye from '../../../../assets/images/icons/eye-closed.svg';
import './InputItem.scss';

const InputItem = ({ type = 'text', label = '', icon = false, onChange, value = '', onKeyDown }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleEyeChange = () => {
    setShowPassword((state) => !state);
  };

  return (
    <div className="input">
      <div className="input-icon">
        <img src={icon} />
      </div>
      <input
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        placeholder={label}
        onChange={onChange}
        value={value}
        onKeyDown={onKeyDown}
      />
      {type === 'password' && (
        <div className="eye-icon">
          <img src={showPassword ? openEye : closedEye} onClick={handleEyeChange} />
        </div>
      )}
    </div>
  );
};

export default InputItem;
