import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Fader from '../../Loaders/Fader';

import './MapUploader.scss';

const MapUploader = ({ id, label, title, selectedFile, setSelectedFile }) => {
  const config = useSelector((state) => state.config.config);
  const settings = useSelector((state) => state.settings);

  const cdnUrl = config.api.cdn;

  const [selectedFileDate, setSelectedFileDate] = useState(null);
  const [browserData, setBrowserData] = useState(null);

  useEffect(() => {
    if (selectedFile.file) {
      const date =
        browserData === 'safari' ? selectedFile.file.lastModified : selectedFile.file.lastModifiedDate.toDateString();
      setSelectedFileDate(date);
    } else {
      setSelectedFileDate(null);
    }
  }, [selectedFile, browserData]);

  const openMap = () => {
    const pdfUrl = `${cdnUrl}/${selectedFile.dbKey}`;
    window.open(pdfUrl, '_blank');
  };

  const deleteMap = () => {
    setSelectedFile({
      dbName: '',
      dbKey: '',
      file: null,
      isNew: true,
    });
  };

  const handleFileChange = (e) => {
    let files = '';
    if (browserData === 'safari') {
      [files] = e.target.files;
    } else {
      [files] = e.currentTarget.files;
    }
    if (files) {
      setSelectedFile((map) => ({ ...map, file: files }));
    } else {
      setSelectedFile((map) => ({ ...map, file: null }));
    }
  };

  const handleCancelFileUpload = () => {
    setSelectedFile((map) => ({ ...map, file: null }));
  };

  useEffect(() => {
    if (settings.browser) {
      setBrowserData(settings.browser);
    }
  }, [settings]);

  if (!browserData) {
    return <Fader />;
  }

  return (
    <div className="upload">
      <span className="upload-label">
        {label}
        <span className="upload-filename">{selectedFile.dbName && `: ${selectedFile.dbName}`}</span>
      </span>
      {selectedFile.dbName && (
        <div className="upload-btns">
          <div className="edit-btn" onClick={openMap}>
            Open
          </div>
          <div className="edit-btn cancel" onClick={deleteMap}>
            Delete
          </div>
        </div>
      )}
      {!selectedFile.dbName && !selectedFile.file && <div className="upload-instructions">{title}</div>}
      {!selectedFile.dbName && (
        <div className="upload-box">
          <label className="edit-btn" htmlFor={`file-upload${id}`}>
            {selectedFile.file ? selectedFile.file.name : 'Select'}
          </label>
          <input type="file" id={`file-upload${id}`} onChange={handleFileChange} />
        </div>
      )}
      {selectedFile.file && (
        <>
          <div className="component-frame">
            <div className="upload-details">Details</div>
            <div className="details-item">
              <div className="di-label">Filename:</div>
              <div className="di-value">{selectedFile.file.name}</div>
            </div>
            <div className="details-item">
              <div className="di-label">Type:</div>
              <div className="di-value">{selectedFile.file.type}</div>
            </div>
            <div className="details-item">
              <div className="di-label">Last Modified:</div>
              <div className="di-value">{selectedFileDate}</div>
            </div>
          </div>
          <div className="upload-btn">
            <div className="edit-btn cancel" onClick={handleCancelFileUpload}>
              Cancel
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MapUploader;
