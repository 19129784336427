import { apiRequestDev } from './apiRequest';

export const getAllDistricts = async (config) => apiRequestDev(config, '/districts/all');

export const getDistrictInfo = async (config, id) => apiRequestDev(config, `/districts/info?id=${id}`);

export const createDistrict = async (
  config,
  districtName,
  representative,
  province,
  election,
  winner,
  prevWinner,
  candidates,
  validVotes,
  rejectedVotes,
  turnout,
  voters,
  swing,
  expenseLimit,
  atlasMapFile,
  detailedMapFile,
) => {
  const formData = new FormData();

  formData.append('districtName', districtName);
  formData.append('representative', representative);
  formData.append('province', province);
  formData.append('election', election);
  formData.append('winner', winner);
  formData.append('prevWinner', prevWinner);
  formData.append('candidates', JSON.stringify(candidates));
  formData.append('validVotes', JSON.stringify(validVotes));
  formData.append('rejectedVotes', JSON.stringify(rejectedVotes));
  formData.append('turnout', JSON.stringify(turnout));
  formData.append('voters', voters);
  formData.append('swing', JSON.stringify(swing));
  formData.append('expenseLimit', expenseLimit);

  if (atlasMapFile) {
    formData.append('atlasMap', atlasMapFile);
  }

  if (detailedMapFile) {
    formData.append('detailedMap', detailedMapFile);
  }

  return apiRequestDev(config, '/districts/create', 'post', formData, true);
};

export const saveDistrict = async (
  config,
  id,
  districtName,
  representative,
  province,
  election,
  winner,
  prevWinner,
  candidates,
  validVotes,
  rejectedVotes,
  turnout,
  voters,
  swing,
  expenseLimit,
  atlasMap,
  detailedMap,
) => {
  const formData = new FormData();

  formData.append('districtName', districtName);
  formData.append('representative', representative);
  formData.append('province', province);
  formData.append('election', election);
  formData.append('winner', winner);
  formData.append('prevWinner', prevWinner);
  formData.append('candidates', JSON.stringify(candidates));
  formData.append('validVotes', JSON.stringify(validVotes));
  formData.append('rejectedVotes', JSON.stringify(rejectedVotes));
  formData.append('turnout', JSON.stringify(turnout));
  formData.append('voters', voters);
  formData.append('swing', JSON.stringify(swing));
  formData.append('expenseLimit', expenseLimit);
  formData.append('isNewAtlasMap', atlasMap.file ? true : atlasMap.isNew);
  formData.append('isNewDetailedMap', detailedMap.file ? true : detailedMap.isNew);

  if (atlasMap.file) {
    formData.append('atlasMap', atlasMap.file);
  }

  if (detailedMap.file) {
    formData.append('detailedMap', detailedMap.file);
  }

  return apiRequestDev(config, `/districts/${id}/update`, 'post', formData, true);
};

export const deleteDistrict = async (config, id) => apiRequestDev(config, `/districts/${id}/delete`, 'delete');

export const assignDistrict = async (config, userId, districtId) => {
  return apiRequestDev(config, '/districts/user', 'post', {
    district_id: districtId[0],
    user_id: userId,
  });
};
